body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Century Gothic', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.responsive-background {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	min-height: calc(85vh)
}

.half-sized-fucker {
	min-width: 50%;
	max-width: 100%;
}

.glass:before {
  content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(0, 0, 0, 0.3);
	filter: blur(10px);
	margin: auto;
}

.glass {
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.8);	
	border-radius: 10px;
	position: relative;
	z-index: 1;
	background: inherit;
	overflow: hidden;
}

.footer_container {
	position: relative;
	z-index: 1;
	background: inherit;
}

.glass-text {
  text-shadow: -1px 1px DarkSlateGrey;
}

.inline-label-text {
	padding: 8px
}

.fade-in-first {
	animation: fadeIn 2s;
	-webkit-animation: fadeIn 2s;
	-moz-animation: fadeIn 2s;
	-o-animation: fadeIn 2s;
	-ms-animation: fadeIn 2s;
  }
  @keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
	0% {opacity:0;}
	100% {opacity:1;}
  }

  .fade-in-second {
	animation: fadeButtons1 2s;
	-webkit-animation: fadeButtons1 2s;
	-moz-animation: fadeButtons1 2s;
	-o-animation: fadeButtons1 2s;
	-ms-animation: fadeButtons1 2s;
  }
  @keyframes fadeButtons1 {
	0% {opacity:0;}
	25% {opacity:0}
	100% {opacity:1;}
  }
  
  @-moz-keyframes fadeButtons1 {
	0% {opacity:0;}
	25% {opacity:0}
	100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeButtons1 {
	0% {opacity:0;}
	25% {opacity:0}
	100% {opacity:1;}
  }
  
  @-o-keyframes fadeButtons1 {
	0% {opacity:0;}
	25% {opacity:0}
	100% {opacity:1;}
  }
  
  @-ms-keyframes fadeButtons1 {
	0% {opacity:0;}
	25% {opacity:0}
	100% {opacity:1;}
  }

  .fade-in-third {
	animation: fadeButtons2 2s;
	-webkit-animation: fadeButtons2 2s;
	-moz-animation: fadeButtons2 2s;
	-o-animation: fadeButtons2 2s;
	-ms-animation: fadeButtons2 2s;
  }
  @keyframes fadeButtons2 {
	0% {opacity:0;}
	50% {opacity:0}
	100% {opacity:1;}
  }
  
  @-moz-keyframes fadeButtons2 {
	0% {opacity:0;}
	50% {opacity:0}
	100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeButtons2 {
	0% {opacity:0;}
	50% {opacity:0}
	100% {opacity:1;}
  }
  
  @-o-keyframes fadeButtons2 {
	0% {opacity:0;}
	50% {opacity:0}
	100% {opacity:1;}
  }
  
  @-ms-keyframes fadeButtons2 {
	0% {opacity:0;}
	50% {opacity:0}
	100% {opacity:1;}
  }